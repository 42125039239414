import React, {FC, useEffect, useState} from 'react';
import {TreeSelect} from 'antd';
import {MaxTagPlaceholderComponent} from '../MaxTagPlaceholderComponent/MaxTagPlaceholderComponent';
import {DisplayValueType} from 'rc-select/lib/interface';
import {TGroupPermission} from '../../types/GroupToken/TGroupPermission';
import {TGroupUserPermission} from '../../types/GroupToken/TGroupUserPermission';

export type PermissionSelectComponentProps = {
    title?: string;
    className?: string;
    onClickHandler?: () => void;
    hidden?: boolean;
    treeData: DisplayValueType[];
    permissions: TGroupPermission[];
    userId: string;
    record: TGroupUserPermission;
};

export const PermissionSelectComponent: FC<PermissionSelectComponentProps> = ({
                                                                                  treeData,
                                                                                  record
                                                                              }) => {

    const [values, setValues] = useState<string[]>([]);

    useEffect(() => {
        setValues(record.permissions || []);
    }, []);


    const onChange = (values: string[]) => {
        setValues(values);
        record.permissions = values;
    }

    const maxTag = (omittedValues: DisplayValueType[]) => {
        return <MaxTagPlaceholderComponent omittedValues={omittedValues}/>
    }

    const filterTreeNode = (input: string, node?: DisplayValueType) => {
        let label = node?.title as string;
        return label.toLowerCase().includes(input.toLowerCase());
    };

    return (

        <React.Fragment>
            <TreeSelect treeData={treeData}
                        treeCheckable={true}
                        multiple={true}
                        maxTagCount={2}
                        onChange={onChange}
                        filterTreeNode={filterTreeNode}
                        value={values}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        maxTagPlaceholder={maxTag}
                        style={{width: '100%'}}/>
        </React.Fragment>
    );

}
