import {TGroupUserToRule} from './TGroupUserToRule';

export class TGroupPermittedUsers  {
    tokenize?: string[];
    detokenize?: string[];
    search?: string[];
    detokenizeWithRules?: TGroupUserToRule[];
    seal?: string[];
    unseal?: string[];
    delete?: string[];

}
