import {BackupModeType} from './BackupModeType';
import dayjs, {Dayjs} from 'dayjs';

export type BackupSettings = {

    mode?: BackupModeType;
    rotation_depth?: number;
    start_time?: string;
    start_weekday?: number;
    start_monthday?: number;

}

export const toDayjs = (timeString: string): dayjs.Dayjs => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    return dayjs().set('hour', hours).set('minute', minutes).set('second', seconds);
}

export const toString = (time: Dayjs): string => {
    return time.format('HH:mm:ss');
}


