import React, {FC} from 'react';
import {Tag} from 'antd';
import {uuid} from '../../utils';
import {BaselineNode} from '../../types/Backup/BaselineNode';

export type RowTagComponentProps = {
    record: BaselineNode[];
};

export const RowTagComponent: FC<RowTagComponentProps> = ({
                                                              record
                                                          }) => {

    return (
        <span> {record?.map(value => {
            return (<span key={uuid()}><Tag>{value.name + ' '}</Tag> </span>)
        })}</span>
    );
}
