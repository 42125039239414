import {fetchWithRefresh} from '../auth';
import {optionsGet, optionsPatch, optionsPost, optionsPut, URL} from '../requst_templates';
import {handleHttpResponse} from '../../utils';
import {BackupForm} from '../../types/Backup/BackupForm';
import {BackupSettings} from '../../types/Backup/BackupSettings';


export const getBackups = async (body: object | null = null) => fetchWithRefresh(
    `${URL}/v1/cluster/snapshot`,
    optionsGet(),
).then(handleHttpResponse);

export const getBackupStatus = async (body: object | null = null) => fetchWithRefresh(
    `${URL}/v1/cluster/snapshot/status`,
    optionsGet(),
).then(handleHttpResponse);

export const createBackup = async (data: BackupForm) => fetchWithRefresh(
    `${URL}/v1/cluster/snapshot/create`, optionsPost(data)
).then(handleHttpResponse);

export const cancelBackup = async (backupName: string) => fetchWithRefresh(
    `${URL}/v1/cluster/snapshot/cancel/${encodeURIComponent(backupName)}`,
    optionsPost(),
).then(handleHttpResponse);

export const deleteBackup = async (backupName: string) => fetchWithRefresh(
    `${URL}/v1/cluster/snapshot/delete/${encodeURIComponent(backupName)}`,
    optionsPost(),
).then(handleHttpResponse);

export const getSettings = async (body: object | null = null) => fetchWithRefresh(
    `${URL}/v1/cluster/snapshot/settings`,
    optionsGet(),
).then(handleHttpResponse);

export const updateSettings = async (settings: BackupSettings) => fetchWithRefresh(
    `${URL}/v1/cluster/snapshot/settings`, optionsPut(settings)
).then(handleHttpResponse);
