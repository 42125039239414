import styles from "./Deletion.module.scss";
import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {JSONSchema7} from 'json-schema';
import {Card, ConfigProvider, Select} from 'antd';
import {CodeEditor} from '../../components/CodeEditor/CodeEditor';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {TokenizeInfo} from '../../components/TokenizeInfo/TokenizeInfo';
import {AntAlert, AntAlertProps} from '../../components/AntAlert/AntAlert';
import {deleteTokensFromGroup, getGroupsByFilter} from '../../services/pages/token_group';
import {getCurrentUser} from '../../utils';
import {RequestFilter} from '../../types/RequestFilter/RequestFilter';
import {TGroupResponse} from '../../types/GroupToken/TGroupResponse';

export function Deletion() {

    const [submitting, setSubmitting] = useState(false);
    const [deletionResult, setDeletionResult] = React.useState([]);
    const [isHidden, setIsHidden] = React.useState(true);
    const [antAlertProps, setAntAlertProps] = useState<AntAlertProps>({severity: '', content: '', show: false});
    const [hasAdminRole, setHasAdminRole] = useState<boolean>(false);
    const [groups, setGroupsData] = useState<TGroupResponse['items'] | undefined>(undefined);
    const [groupId, setGroupId] = useState<string>();

    const [tokens, setTokens] = useState<string[]>([]);

    const [cleanCounter, setCleanCounter] = useState<number>(1);
    const [defaultCodeEditorValue, setDefValue] = useState([""]);

    useEffect(() => {
        getAllGroups();
        let cUser = getCurrentUser();
        setHasAdminRole(cUser?.roles.indexOf('admin') !== -1);
    }, []);

    const getAllGroups = () => {
        let filter = new RequestFilter();
        filter.limit = 1000;
        filter.offset = 0;
        filter.sortBy = 'name';
        filter.sortOrder = 'asc';

        getGroupsByFilter(filter).then((resp) => {
            console.log(resp);
            setGroupsData(resp['items']);
        });
    }

    const doDeletion = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrors(validateValues(tokens));
        setSubmitting(true);
    }

    const validateValues = (tokens: string[]) => {
        let errors = {
            tokens: "",
            group: ""
        };
        if (!groupId) {
            errors.group = "group обязательное к заполнению поле";
        }
        return errors;
    };

    const [errors, setErrors] = useState({
        tokens: "",
        group: ""
    });

    useEffect(() => {
        if (!hasErrors() && submitting) {
            finishSubmit();
        }
        if (hasErrors()) {
            setAntAlertProps({severity: 'error', content: 'Ошибка удаления' + String.fromCharCode(10) + errors.group + errors.tokens, show: true});
        }
    }, [errors]);

    const hasErrors = () => {
        let res = false;
        if (errors.tokens) res = true;
        if (errors.group) res = true;
        return res;
    }

    const finishSubmit = () => {
        deleteTokensFromGroup(groupId || '', tokens).then((res) => {
            if (res.errors != undefined && res.errors.length > 0) {
                setAntAlertProps({severity: 'error', content: 'Ошибка удаления \n' + res.errors[0].code + ' ' + res.errors[0].message, show: true})
                setIsHidden(true);
            } else {
                setAntAlertProps({severity: 'success', content: 'Токены успешно удалены', show: true})
                setIsHidden(false);
                setDeletionResult(res);
            }
        }).catch((error) => {
            setAntAlertProps({severity: 'error', content: 'Ошибка удаления ', show: true})
            setIsHidden(true);
        });
    }


    const alertClose = () => {
        setAntAlertProps({severity: 'info', content: '', show: false})
    }

    const handleCodeEditorOnChange = (val: string) => {
        try {
            let tokens = JSON.parse(val);
            setTokens(tokens);
        } catch (exc) {
            console.log(exc);
        }
    }

    const clearValues = () => {
        let counter = cleanCounter + 1;
        setCleanCounter(counter);
        if (cleanCounter % 2 == 0) {
            setDefValue([""]);
        } else {
            setDefValue([" "]);
        }
    }

    const copyHandler = () => {
        if (deletionResult.length != 0) {
            navigator
                .clipboard
                .writeText(JSON.stringify(deletionResult, null, 2))
                .then(() =>
                    setAntAlertProps({severity: 'success', content: 'Результат удаления скопирован в буфер обмена', show: true})
                );
        }
    }

    const deletion_schema = {
        type: "object",
        maxProperties: 2,
        minProperties: 2,
        title: "deletion",
        properties: {
            type: "array",
            items: {
                type: "string"
            }
        }

    };

    const onGroupChange = (val: string) => {
        console.log(val);
        setGroupId(val);
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        colorErrorBorder: '#D92020',
                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder: '#D92020',
                        algorithm: true,
                    },
                    Checkbox: {
                        colorPrimary: '#F75623',
                        algorithm: true
                    }
                },
            }}>
            <Card bordered={false} style={{width: '100%', height: '100%'}} key={'deletion'}>

                <div className={styles.page}>

                    <div className="header_1">
                        Удаление
                    </div>

                    <div className={styles.form}>

                        <div className={styles.form_left}>

                            <div className='card-body'>
                                <form onSubmit={(e) => doDeletion(e)}>
                                    <div>
                                        <div style={{marginBottom: '5px'}}>
                                            <label>Группа токенизации
                                                <span className={styles.error}>
                                                *
                                            </span>
                                            </label>
                                        </div>

                                        <Select style={{width: '100%', marginBottom: '15px'}}
                                                onChange={(val) => onGroupChange(val)}
                                                value={groupId}
                                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                                placeholder={'Выберите из списка'}
                                                filterOption={filterOption}
                                                options={groups?.map((group) => ({label: group.name, value: group.id || ''}))}
                                                showSearch/>


                                        <div style={{marginBottom: '5px'}}>
                                            <label>
                                                Токены для удаления
                                            </label>
                                        </div>

                                        <CodeEditor defaultValue={defaultCodeEditorValue}
                                                    editable={true}
                                                    dataSchema={deletion_schema as JSONSchema7}
                                                    onChangeHandler={handleCodeEditorOnChange}/>

                                    </div>

                                    <div style={{marginTop: '10px'}}>

                                        <ActionButton btnText={'Удалить'}
                                                      htmlType={'submit'}
                                                      type={'primary'}/>

                                        <span style={{marginLeft: '16px'}}>
                                    <ActionButton btnText={'Копировать результат'}
                                                  onClickHandler={copyHandler}
                                                  className={'button_1 primary_80'}
                                                  htmlType={'button'}
                                                  type={'text'}/>

                                    </span>

                                        <span style={{marginLeft: '16px'}}>
                                    <ActionButton btnText={'Очистить значения'}
                                                  onClickHandler={clearValues}
                                                  className={'button_1 primary_80'}
                                                  htmlType={'button'}
                                                  type={'text'}/>
                                    </span>
                                    </div>
                                </form>

                            </div>
                        </div>

                        <div className={styles.form_right}>
                            <div hidden={!isHidden}>
                                <div style={{marginBottom: '5px'}}>
                                    <label>

                                    </label>
                                </div>

                                <TokenizeInfo action={'Удалить'} formFields={'tokens'}/>
                            </div>

                            <div hidden={isHidden} style={{marginTop: '67px'}}>
                                <div style={{marginBottom: '5px'}}>
                                    <label>
                                        Результат
                                    </label>
                                </div>
                                <CodeEditor defaultValue={deletionResult} editable={false}/>
                            </div>
                        </div>
                    </div>

                    <div className={styles.notification}>

                        <AntAlert severity={antAlertProps.severity}
                                  content={antAlertProps.content}
                                  onClose={() => alertClose()}
                                  show={antAlertProps.show}/>
                    </div>
                </div>
            </Card>
        </ConfigProvider>
    );
}
