/* eslint-disable react/function-component-definition */
import classNames from 'classnames';
import React, {FC} from 'react';
import {useNavigate, useRoutes} from 'react-router-dom';
import {Tooltip} from 'react-tooltip';


import styles from './Aside.module.scss';
import {urls} from '../../mocks/urls';
import {doNavigate, i18n} from '../../utils';

import {Card, ConfigProvider} from 'antd';
import {
    AppstoreOutlined,
    CloudUploadOutlined,
    CloudDownloadOutlined,
    ProfileOutlined,
    UserOutlined,
    KeyOutlined,
    BookOutlined,
    GroupOutlined,
    DeleteOutlined, SettingOutlined, PieChartOutlined, InteractionOutlined
} from '@ant-design/icons';


export const Aside: FC<any> = ({pathname}) => {
    const navigate = useNavigate();

    const toolsLinksSettings = [
        {
            title: urls[3].group_token,
            href: 'tools/groups',
            count: 0,
        },
        {
            title: urls[3].tokenization,
            href: 'tools/tokenization',
            count: 1,
        },
        {
            title: urls[3].detokenization,
            href: 'tools/detokenization',
            count: 2,
        },
        {
            title: urls[3].deletion,
            href: 'tools/deletion',
            count: 3,
        }
    ];

    const controlLinksSettings = [
        {
            title: urls[4].group_token,
            href: 'control/groups',
            count: 0,
        },
        {
            title: urls[4].templates,
            href: 'control/templates',
            count: 1,
        },
        {
            title: urls[4].users,
            href: 'control/users',
            count: 2,
        },
        {
            title: urls[4].dictionaries,
            href: 'control/dictionaries',
            count: 3,
        },
        {
            title: urls[4].rules,
            href: 'control/rules',
            count: 4,
        },
        {
            title: urls[4].backup,
            href: 'control/backup',
            count: 4,
        }
    ];

    const getOutletLinkPart = () => {
        let parts = pathname.split("/");

        return parts.length <= 3 ? pathname.substring(1) : parts[1] + "/" + parts[2];
    }

    const links = () => {
        let firstRoutePart = pathname.split("/")[1];

        switch (firstRoutePart) {
            case "tools":
                return toolsLinksSettings;
            case "control":
                return controlLinksSettings;
        }
    }

    let linksSettings = links();

    const getMenuIcon = (title: string): any => {

        switch (title) {

            case 'Group_token' :
                return <AppstoreOutlined className={styles.item_icon} />;
            case 'Tokenization' :
                return <CloudUploadOutlined className={styles.item_icon}/>;
            case 'Detokenization' :
                return <CloudDownloadOutlined className={styles.item_icon}/>;
            case 'Templates':
                return <ProfileOutlined className={styles.item_icon}/>;
            case 'Users':
                return <UserOutlined className={styles.item_icon}/>;
            case 'Permissions':
                return <KeyOutlined className={styles.item_icon}/>;
            case 'Dictionaries':
                return <BookOutlined className={styles.item_icon}/>;
            case 'Rules':
                return <GroupOutlined className={styles.item_icon}/>;
            case 'Deletion':
                return <DeleteOutlined className={styles.item_icon}/>;
            case 'Backup':
                return <InteractionOutlined className={styles.item_icon}/>;
            default:
                return '';
        }

    }

    const menu = useRoutes([
        {
            path: '/*',
            element: (
                <ul>
                    {linksSettings?.map((link) => (
                        <li key={link.href}>
                            <a
                                id={`icon${link.href}`}
                                data-tooltip-content={i18n(link.title)}
                                href=""
                                className={classNames(styles.link, {
                                    [styles.link_active]: link.href === getOutletLinkPart(),
                                })}
                                onClick={(e) => {
                                    e.preventDefault();
                                    doNavigate(`/${link.href}`, navigate);
                                    return false;
                                }}>
                                {getMenuIcon(link.title)} {i18n(link.title)}
                            </a>
                            <Tooltip anchorId={`icon${link.href}`} noArrow className="tooltip mob-only" place="right"/>
                        </li>
                    ))}
                </ul>
            ),
        }
    ]);

    return (
        <>
            <div className={styles.aside}>

                <ConfigProvider
                    theme={{
                        components: {

                        },
                    }}
                >
                <Card bordered={false} style={{width: 304}} key={'aside'}>
                    <nav>{menu}</nav>
                </Card>

                </ConfigProvider>
            </div>
        </>
    );
};
