import React, {FC, useEffect, useState} from 'react';
import {Popover, Tag, Tooltip} from 'antd';
import {i18n, uuid} from '../../utils';
import {BackupRecord} from '../../types/Backup/BackupRecord';
import styles from './BackupStatus.module.scss';

export type BackupStatusProps = {
    status: BackupRecord;
};

export const BackupStatus: FC<BackupStatusProps> = ({
                                                        status
                                                    }) => {

    const [className, setClassName] = useState<string>();

    const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);
    const [isPopoverVisible, setPopoverVisible] = useState<boolean>(false);

    useEffect(() => {
        let className = getClassName(status?.state || '');
        setClassName(className);
    }, []);

    const getClassName = (status: string): string => {
        switch (status) {
            case 'PROCESSING':
            case 'STARTED':
                return 'status_processing';

            case 'FINISHED':
                return 'status_finished';
            case 'ERROR':
                return 'status_error'
            default:
                return 'status_finished';
        }
    }

    const onTagClick = () => {
        setPopoverVisible(!isPopoverVisible);
        setTooltipVisible(!isTooltipVisible);
    }

    const onMouseLeave = () => {
        setTooltipVisible(false);
    }

    const onMouseEnter = () => {
        setTooltipVisible(true);
    }

    const getTitle = (status: BackupRecord): string => {
        let trimText = status.last_error?.substring(0, 25);
        trimText = trimText === undefined ? '' : trimText;
        return trimText + '...click for detail';
    }

    const content = (
        <div >
            <p>{status?.last_error}</p>
        </div>
    );

    const isVisible = (record: BackupRecord): boolean => {
        return record?.state === 'ERROR';
    }

    return (
        <span key={uuid()} className={styles.tag}>
            <Popover content={content}
                     visible={isPopoverVisible && isVisible(status)}
                     title={''}
                     overlayStyle={{width:'50%',}}
                     placement={'leftBottom'}
                     getPopupContainer={() => document.getElementById('backup')!}>

            <Tooltip title={getTitle(status)}
                     visible={(isTooltipVisible && !isPopoverVisible) && isVisible(status)}
                     getPopupContainer={() => document.getElementById('backup')!}>
            <Tag className={className}
                 onClick={onTagClick}
                 onMouseLeave={onMouseLeave}
                 onMouseEnter={onMouseEnter}>
                {i18n('BACKUP_' + status?.state)}
            </Tag>
            </Tooltip>
             </Popover>
        </span>
    );
}

