import React, {FC, useEffect, useState} from 'react';
import {ConfigProvider, Empty, Table} from 'antd';

import styles from './Groups.module.scss';
import {PlusCircleFilled} from '@ant-design/icons';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {TUser} from '../../types/User';

import {uuid} from '../../utils';
import {RowDeleteComponent} from '../../components/RowDeleteComponent/RowDeleteComponent';
import {RowSelectComponent} from '../../components/RowSelectComponent/RowSelectComponent';
import {TGroupUserPermission} from '../../types/GroupToken/TGroupUserPermission';
import {TGroup} from '../../types/GroupToken/TGroup';
import {TGroupPermission} from '../../types/GroupToken/TGroupPermission';
import {RuleSelectComponent} from '../../components/RuleSelectComponent/RuleSelectComponent';
import {MRule} from '../../types/MaskingRule/MRule';
import {TGroupUserToRule} from '../../types/GroupToken/TGroupUserToRule';
import {findIndex} from 'lodash';
import {TGroupPermittedUsers} from '../../types/GroupToken/TGroupPermittedUsers';
import {PermissionSelectComponent} from '../../components/PermissionSelectComponent/PermissionSelectComponent';

export type GroupUserPermissionProps = {
    className?: string;
    users?: TUser [];
    hasAdminRole?:boolean;
    maskingRules?: MRule [];
    isValidate: boolean;
    tGroup?: TGroup;
    onValidateFinishHandler: (hasError: boolean) => void;
    onSetPermissionHandler:(permittedUsers: TGroupPermittedUsers) => void;
    userPermission?: TGroupUserPermission[];
};

export const GroupUserPermission: FC<GroupUserPermissionProps> = ({
                                                                      className,
                                                                      users,
                                                                      hasAdminRole,
                                                                      maskingRules,
                                                                      isValidate,
                                                                      tGroup,
                                                                      onSetPermissionHandler,
                                                                      onValidateFinishHandler,
                                                                      userPermission

                                                                  }) => {

    const [isAddUserHidden, setIsAddUserHidden] = useState<boolean>(true);

    const [data, setData] = useState<TGroupUserPermission[]>([]);

    const [hoverRowId, setHoverRowId] = useState<string>('');

    const [hasPermissionError, setPermissionError] = useState(false);


    const [editingRecord, setEditingRecord] = useState(null);

    const [tokenizePermittedUsers, setTokenizePermittedUsers] = React.useState<string[]>([]);
    const [detokenizePermittedUsers, setDetokenizePermittedUsers] = React.useState<string[]>([]);
    const [detokenizePermittedUsersWithRule, setDetokenizePermittedUsersWithRule] = React.useState<TGroupUserToRule[]>([]);
    const [searchPermittedUser, setSearchPermittedUser] = React.useState<string[]>([]);
    const [deletePermittedUsers, setDeletePermittedUser] = React.useState<string[]>([]);
    const [sealPermittedUsers, setSealPermittedUser] = React.useState<string[]>([]);
    const [unsealPermittedUsers, setUnsealPermittedUser] = React.useState<string[]>([]);

    const [dataPermission, setDataPermission] = useState<TGroupUserPermission[]>([]);

    let locale = {
        emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                   description={'Нет данных'}/>
        )
    };

    useEffect(() => {

        if (isValidate) {
            setPermissionError(hasError);
            tokenizePermittedUsers.length=0;
            detokenizePermittedUsers.length=0;
            searchPermittedUser.length=0;
            detokenizePermittedUsersWithRule.length=0;
            convertValues();
            onSetPermissionHandler(getPermittedUsers());
            onValidateFinishHandler(hasError());
        }
    }, [isValidate]);

    const getPermittedUsers = (): TGroupPermittedUsers => {

        let permittedUsers = new TGroupPermittedUsers();
        permittedUsers.tokenize = tokenizePermittedUsers;
        permittedUsers.detokenize = detokenizePermittedUsers;
        permittedUsers.search = searchPermittedUser;
        permittedUsers.detokenizeWithRules = detokenizePermittedUsersWithRule;
        permittedUsers.seal = sealPermittedUsers;
        permittedUsers.unseal = unsealPermittedUsers;
        permittedUsers.delete = deletePermittedUsers;

        return permittedUsers;
    }

    useEffect(() => {
        if (tGroup?.permissions) {
            convertPermission(tGroup['permissions']);
            setData((prev) => [...prev, ...dataPermission]);
            setIsAddUserHidden(false);
        }
    }, [])

    const hasError = () => {
        let hasError = false;
        if (!data) return hasError;

        data.forEach(record => {
            hasError = hasErrorForRow(record);
        });

        return hasError;
    }

    const hasErrorForRow = (record: TGroupUserPermission): boolean => {
        let hasError = false;
        if (!record.userId) {
            hasError = true;
        }
        if (record.userId &&
            (!record.permissions?.length)) {
            hasError = true;
        }
        return hasError;
    }

    const convertValues = () => {
        let tokenize = tokenizePermittedUsers;
        let detokenize = detokenizePermittedUsers;
        let detokinizeWithRules = detokenizePermittedUsersWithRule;
        let search = searchPermittedUser;

        let del = deletePermittedUsers;
        let seal = sealPermittedUsers;
        let unseal = unsealPermittedUsers;


        data.forEach(record => {

            console.log(record);

            if (record.permissions?.includes("tokengroup_tokenize")) {
                pushIfNotContains(tokenize, record.userId);
            }
            if (record.permissions?.includes("tokengroup_detokenize")) {
                pushIfNotContains(detokenize, record.userId);
                if (record.maskingRuleId) {
                    const newUserToRule = {
                        user_id: record.userId,
                        masking_rule_id: record.maskingRuleId
                    }
                    let index = findIndex(detokinizeWithRules, newUserToRule);
                         if (index == -1) {
                             detokinizeWithRules.push(newUserToRule);
                         }
                }
            }

            if (record.permissions?.includes("tokengroup_search")) {
                pushIfNotContains(search, record.userId);
            }
            if (record.permissions?.includes("tokengroup_delete_items")) {
                pushIfNotContains(del, record.userId);
            }
            if (record.permissions?.includes("tokengroup_seal")) {
                pushIfNotContains(seal, record.userId);
            }
            if (record.permissions?.includes("tokengroup_unseal")) {
                pushIfNotContains(unseal, record.userId);
            }
        });

        setTokenizePermittedUsers([...tokenize]);
        setDetokenizePermittedUsers([...detokenize]);
        setDetokenizePermittedUsersWithRule([...detokinizeWithRules]);
        setSearchPermittedUser([...search]);
        setDeletePermittedUser([...del]);
        setSealPermittedUser([...seal]);
        setUnsealPermittedUser([...unseal]);

    }

    const convertPermission = (permissions: TGroupPermission[] | undefined) => {

            let dataP = dataPermission;

            permissions?.forEach((perm) => {

                const tGroupPermission = dataP.find((val) => val.userId === perm.object_id)
                if (!tGroupPermission) {
                    const newPermission: TGroupUserPermission = {
                        rowId: uuid(),
                        userId: perm.object_id,
                        permissions:[],
                        maskingRuleId: perm.masking_rule_id||''
                    }
                    newPermission.permissions?.push(perm.permission);
                    dataP.push(newPermission);
                } else {
                    if(perm.masking_rule_id){
                        Object.assign(tGroupPermission, {maskingRuleId: perm.masking_rule_id});
                    }
                    tGroupPermission.permissions?.push(perm.permission);

                }
            });

            setDataPermission((prev) => [...prev, ...dataP]);
    }

    const pushIfNotContains = (data: string[], val: string) => {
        let index = data.indexOf(val);

        if (index == -1) {
            data.push(val);
        }
    }

    const addGroupUserPermission = () => {
        setIsAddUserHidden(false);
        const newGroupUserPermission: TGroupUserPermission = {
            rowId: uuid(),
            userId: '',
            maskingRuleId: ''
        }

        setData((pre) => {
            return [...pre, newGroupUserPermission]
        });
    }

    const deleteHandler = (record: any) => {
        let removed = data.filter(user => user.rowId != record.rowId);
        setData([...removed]);
    }

    const treeData = [{
        title: 'Токенизация',
        value: 'tokengroup_tokenize',
        key: 'tokengroup_tokenize',
    }, {
        title: 'Детокенизация',
        value: 'tokengroup_detokenize',
        key: 'tokengroup_detokenize',
    }, {
        title: 'Поиск',
        value: 'tokengroup_search',
        key: 'tokengroup_search',
    }, {
        title: 'Удаление',
        value: 'tokengroup_delete_items',
        key: 'tokengroup_delete_items',
    }, {
        title: 'Запечатывание',
        value: 'tokengroup_seal',
        key: 'tokengroup_seal',
    }, {
        title: 'Снятие запечатывания',
        value: 'tokengroup_unseal',
        key: 'tokengroup_unseal',
    }]

    const columns = [
        {
            key: '0',
            title: 'userId',
            dataIndex: 'userId',
            hidden: true
        },
        {
            key: '1',
            title: 'rowId',
            dataIndex: 'rowId',
            hidden: true
        },
        {
            key: '2',
            title: 'Пользователь',
            width: '30%',
            render: (record: TGroupUserPermission) => {
                return <RowSelectComponent users={users}
                                           hasAdminRole={hasAdminRole}
                                           record={record}
                                           val={record.userId}/>
            }
        },
        {
            key: 3,
            width: '30%',
            title: 'Права',
            render: (record: TGroupUserPermission) => {
                return <PermissionSelectComponent treeData={treeData}
                                                  permissions={tGroup?.permissions || []}
                                                  record={record}
                                                  userId={record.userId}/>
            }
        },
        {
            key: '9',
            title: 'Правило маскирования',
            width: '30%',
            render: (record: TGroupUserPermission) => {
                return <>{record.permissions?.includes('tokengroup_detokenize') ?
                    <RuleSelectComponent
                        record={record}
                        hasAdminRole={hasAdminRole}
                        val={record.maskingRuleId}
                        maskingRules={maskingRules}/> :
                    '—'}
                </>
            }

        },
        {
            key: '10',
            width: 100,

            render: (record: any) => {
                return <>{hasAdminRole ? <RowDeleteComponent hidden={(hoverRowId != record.rowId)}
                                                             title={'Удалить права пользователя'}
                                                             onClickHandler={() => deleteHandler(record)}/> : <></>}
                </>
            }
        }
    ]

    return (
        <ConfigProvider
            theme={{
                token:{
                    colorPrimary: '#F75623',
                    colorBorder: '#F75623',
                },
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorBorder: 'none',
                        colorPrimaryHover: '#F75623',
                    },
                    Checkbox: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623'
                    },
                    Table: {
                        headerBg: '#FFFFFF'
                    },
                    TreeSelect: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',

                    }
                },
            }}>


            <div className={'body_1_long'} hidden={!isAddUserHidden}>
                <PlusCircleFilled className={styles.user_item_icon}/>

                <div style={{width: '434px'}} className={'body_1_long neutral_90'}>
                    Для работы пользователи с токенами необходимо добавить пользователей и настроить права.
                </div>


            </div>

            <div hidden={isAddUserHidden}>


                <Table
                    onRow={(record, rowIndex) => {
                        return {
                            onMouseEnter: () => {
                                setHoverRowId(record.rowId);
                            },
                            onMouseLeave: () => {
                                setHoverRowId('');
                            }
                        };
                    }}
                    rowKey={'rowId'}
                    rowClassName={(record ) => {
                        return hasErrorForRow(record) ? styles.row_error : ''
                    }}
                    locale={locale}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    size="small"/>


            </div>

            <div style={{marginTop: '25px'}}>
                <ActionButton type={'default'}
                              htmlType={'button'}
                              hidden={!hasAdminRole}
                              className={'button_1'}
                              onClickHandler={addGroupUserPermission}
                              btnText={'Добавить пользователя'}/>
            </div>

        </ConfigProvider>
    )

}
