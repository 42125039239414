import { fetchWithRefresh } from '../auth';
import {
  optionsGet,
  optionsPost, optionsPatch, optionsDelete,
  URL,
} from '../requst_templates';
import { handleHttpResponse } from '../../utils';
import {RequestFilter, toUrl} from '../../types/RequestFilter/RequestFilter';


export const getTokenGroups = async (body: object | null = null) => fetchWithRefresh(
  `${URL}/v1/token_group`,
  optionsGet(),
).then(handleHttpResponse);

export const getTokenGroup = async (id: string) => fetchWithRefresh(
  `${URL}/v1/token_group/${encodeURIComponent(id)}`,
  optionsGet(),
).then(handleHttpResponse);

export const getTokenGroupsPageable = async (limit:number, offset:number) => fetchWithRefresh(
    `${URL}/v1/token_group?limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(offset)}`,
    optionsGet(),
).then(handleHttpResponse);

export const getTokenGroupWithPermissions = async (id: string) => fetchWithRefresh(
  `${URL}/v1/token_group/${encodeURIComponent(id)}/?include_permissions=true`,
  optionsGet(),
).then(handleHttpResponse);

export const getGroupsByFilter = async (filter: RequestFilter) => fetchWithRefresh(
    `${URL}/v1/token_group${toUrl(filter)}`,
    optionsGet(),
).then(handleHttpResponse);

export const createTokenGroup = async (data: any) => fetchWithRefresh(
  `${URL}/v1/token_group/`, optionsPost(data)
).then(handleHttpResponse);

export const updateTokenGroup = async (id: string, data: any) => fetchWithRefresh(
  `${URL}/v1/token_group/${encodeURIComponent(id)}`,
  optionsPatch(data),
).then(handleHttpResponse);

export const createTokenGroupPermission = async (id: string, data: any) => fetchWithRefresh(
  `${URL}/v1/token_group/${encodeURIComponent(id)}/permissions`, optionsPost(data)
).then(handleHttpResponse);

export const deleteTokenGroupPermission = async (id: string, data: any) => fetchWithRefresh(
  `${URL}/v1/token_group/${encodeURIComponent(id)}/permissions`, optionsDelete(data)
).then(handleHttpResponse);

export const deleteTokenGroup = async (id: string) => fetchWithRefresh(
  `${URL}/v1/token_group/${encodeURIComponent(id)}`,
  optionsDelete(),
).then(handleHttpResponse);

export const sealTokenGroup = async (groupId: string ) => fetchWithRefresh(
    `${URL}/v1/token_group/${encodeURIComponent(groupId)}/seal`,
    optionsPost(),
).then(handleHttpResponse);

export const unsealTokenGroup = async (groupId: string ) => fetchWithRefresh(
    `${URL}/v1/token_group/${encodeURIComponent(groupId)}/unseal`,
    optionsPost(),
).then(handleHttpResponse);

export const clearTokenGroup = async (groupId: string ) => fetchWithRefresh(
    `${URL}/v1/token_group/${encodeURIComponent(groupId)}/clear`,
    optionsPost(),
).then(handleHttpResponse);

export const deleteTokensFromGroup = async (id: string, tokens: string[]) => fetchWithRefresh(
    `${URL}/v1/token_group/${encodeURIComponent(id)}/tokens`,
    optionsDelete(tokens),
).then(handleHttpResponse);
