import React, {FC, useEffect, useState} from 'react';
import {ConfigProvider, Input, Modal} from 'antd';
import {DictionaryItem} from '../../types/DictionaryItem';
import {BackupForm} from '../../types/Backup/BackupForm';

export type BackupModalProps = {
    title: string;
    editDictionary?: DictionaryItem | null;
    isModalOpen: boolean;
    onConfirmHandler: (form: BackupForm) => void;
    onCancelHandler: () => void;
};


export const BackupModal: FC<BackupModalProps> = ({
                                                      title,
                                                      isModalOpen,
                                                      onCancelHandler,
                                                      onConfirmHandler

                                                  }) => {

    const defaultForm = {name: '', sync: false};
    const [form, setForm] = useState<BackupForm>(defaultForm);

    const onSave = () => {
        onConfirmHandler(form);
    }

    const onCancel = () => {
        clearForm();
        onCancelHandler();
    }

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prev) => ({...prev, name: e.target.value}))
    }


    const clearForm = () => {
        setForm(defaultForm);
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                        colorBorder: '1px solid #F75623',
                        colorPrimaryBorder: '1px solid #F75623',
                        algorithm: true
                    },
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        colorErrorBorder: '#D92020',
                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder: '#D92020',
                        colorBorder: '2px solid #F75623',
                        colorPrimaryBorder: '2px solid #F75623',
                        boxShadow: ' 1px #F75623',
                        colorText: '#23262D',
                        colorTextPlaceholder: '#717681',

                        algorithm: true,
                    },
                    Modal: {
                        colorPrimary: '#F75623',
                        colorBorder: '2px solid #F75623',
                        colorPrimaryBorder: '2px solid #F75623',
                        algorithm: true
                    },
                    Switch: {
                        colorPrimary: '#F75623',
                        algorithm: true
                    },

                },
            }}>

            <Modal title={<span className={'header_2 neutral_90'}> {title} </span>}

                   open={isModalOpen}
                   width={560}
                   onOk={onSave}
                   onCancel={onCancel}
                   cancelText={'Отменить'}
                   okText={'Запустить'}
                   getContainer={() => document.getElementById("backup_page")!}>


                <div style={{width: '100%'}}>
                    <div className={'body_1_long neutral_50'}>
                        Название(опционально)
                    </div>
                    <div>
                        <Input name="name"
                               style={{marginTop: '5px'}}
                               value={form.name}
                               onChange={(e) => onInputChange(e)}/>

                    </div>
                </div>
            </Modal>
        </ConfigProvider>
    )
}
