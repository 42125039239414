import {Query} from './Query';

export class RequestFilter {

    limit?: number;
    offset?: number;
    sortBy?: string;
    sortOrder?: string;
    query?: Query;

    public setLimit(limit: number): void {
        this.limit = limit;
    }

    setOffset(offset: number): void {
         this.offset = offset;
    }

    setQuery(query: Query): void {
       this.query = query;
    }

    setSortBy(sortBy: string): void {
         this.sortBy = sortBy;
    }

    setSortOrder(sortOrder: string): void {
      this.sortOrder = sortOrder;
    }
}

export const toUrl = (filter?: RequestFilter): string => {

    let url = "";

    if (filter) {
        url += "?";
        if (!(filter.limit === null || filter.limit === undefined)) {
            url += "limit=";
            url += encodeURIComponent(filter.limit);
        }
        if (!(filter.offset === null || filter.offset === undefined)) {
            url += "&";
            url += "offset=";
            url += encodeURIComponent(filter.offset);
        }
        if (filter.sortBy) {
            url += "&";
            url += "sort_by=";
            url += encodeURIComponent(filter.sortBy);
        }
        if (filter.sortOrder) {
            url += "&";
            url += "sort_order=";
            url += encodeURIComponent(filter.sortOrder);
        }
        let query = filter.query;
        if (query) {
            url += "&query=";
            url += query.field;
            url += " " + query.op;
            url += encodeURIComponent(query.value || "");
        }
    }

    return url;
}
