import React, {FC, useEffect, useState} from 'react';
import {ConfigProvider, Input, Select, TimePicker} from 'antd';
import {BackupForm} from '../../types/Backup/BackupForm';
import styles from './Backup.module.scss';
import {BackupModeType} from '../../types/Backup/BackupModeType';
import {Dayjs} from 'dayjs';
import {BackupSettings, toDayjs} from '../../types/Backup/BackupSettings';

export type BackupCommonSettingsProps = {

    onConfirmHandler?: (form: BackupForm) => void;
    onCancelHandler?: () => void;
    backupSettings?: BackupSettings;
    onModeChange: (mode: BackupModeType) => void
    onRotationDepthChange: (rotationDept: string) => void;
    onTimeChange: (time: Dayjs) => void;
    onWeekDayChange: (weekDay: number) => void;
    onMonthDayChange: (monthDay: number) => void;


};


export const BackupCommonSettings: FC<BackupCommonSettingsProps> = ({
                                                                        backupSettings,
                                                                        onModeChange,
                                                                        onRotationDepthChange,
                                                                        onTimeChange,
                                                                        onWeekDayChange,
                                                                        onMonthDayChange
                                                                    }) => {

    const [mode, setMode] = useState<BackupModeType>();

    useEffect(() => {
        setMode(backupSettings?.mode);
    }, [backupSettings]);


    let modeOptions = [
        {label: 'никогда', value: BackupModeType.NONE},
        {label: 'ежедневно', value: BackupModeType.DAILY},
        {label: 'еженедельно', value: BackupModeType.WEEKLY},
        {label: 'ежемесячно', value: BackupModeType.MONTHLY},
    ]

    let days = [
        {label: 'понедельник', value: 1},
        {label: 'вторник', value: 2},
        {label: 'среда', value: 3},
        {label: 'четверг', value: 4},
        {label: 'пятница', value: 5},
        {label: 'суббота', value: 6},
        {label: 'воскресенье', value: 7},
    ]

    const initMonthDay = (): any[] => {
        let arr: any[] = [];
        for (let i = 1; i < 32; i++) {
            arr.push({label: '' + i, value: i});
        }
        return arr;
    }

    let mDays = initMonthDay();

    const onChangeRegularity = (val: BackupModeType) => {
        setMode(val);
        onModeChange(val)
    }

    const isHidden = (fieldName: string): boolean => {
        let isHidden = false;

        switch (fieldName) {
            case 'time':
            case 'count':
                isHidden = mode === undefined || mode === BackupModeType.NONE;
                break;

            case 'week_day':
                isHidden = mode === undefined ||
                    mode === BackupModeType.NONE ||
                    mode === BackupModeType.DAILY ||
                    mode === BackupModeType.MONTHLY;
                break;

            case 'month_day':
                isHidden = mode === undefined ||
                    mode === BackupModeType.NONE ||
                    mode === BackupModeType.DAILY ||
                    mode === BackupModeType.WEEKLY;
                break;
        }


        return isHidden;
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#F75623',
                    colorLink: '#F75623',
                },
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                        colorBorder: '1px solid #F75623',
                        colorPrimaryBorder: '1px solid #F75623',
                        algorithm: true
                    },
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        colorErrorBorder: '#D92020',

                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder: '#D92020',
                        algorithm: true,
                    },
                    Modal: {
                        colorPrimary: '#F75623',
                        colorBorder: '2px solid #F75623',
                        colorPrimaryBorder: '2px solid #F75623',
                        algorithm: true
                    },
                    Switch: {
                        colorPrimary: '#F75623',
                        algorithm: true
                    }
                },
            }}>

            <div id="backup_settings" className={styles.settings}>
                <div className={styles.settings_row_text}>

                    <div className={styles.settings_f_cell}>
                        Регулярность
                        <span className={styles.required}>
                            *
                        </span>
                    </div>

                    <div className={styles.settings_s_cell}>
                        <span hidden={isHidden('count')}>
                        Количество
                        <span className={styles.required}>
                            *
                        </span>
                        </span>
                    </div>

                    <div className={styles.settings_s_cell}>
                        <span hidden={isHidden('time')}>
                        Время
                        <span className={styles.required}>
                            *
                        </span>
                        </span>
                    </div>

                    <div className={styles.settings_s_cell}>
                       <span hidden={isHidden('week_day')}>
                        День недели
                        <span className={styles.required}>
                            *
                        </span>
                        </span>

                        <span hidden={isHidden('month_day')}>
                                День месяца
                                <span className={styles.required}>
                                    *
                                </span>
                        </span>
                    </div>
                </div>

                <div className={styles.settings_row}>
                    <div className={styles.settings_f_cell}>
                        <Select id='mode'
                                value={backupSettings?.mode}
                                style={{width: '100%'}}
                                onChange={onChangeRegularity}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                placeholder={'Выберите значение'}
                                options={modeOptions}/>
                    </div>
                    <div className={styles.settings_s_cell}>
                        <span hidden={isHidden('count')}>
                        <Input id='count'
                               value={backupSettings?.rotation_depth}
                               onChange={(e) => onRotationDepthChange(e.target.value)}/>
                         </span>
                    </div>
                    <div className={styles.settings_s_cell}>
                        <span hidden={isHidden('time')}>
                            <TimePicker
                                id='time'
                                style={{width: '100%'}}
                                value={toDayjs(backupSettings?.start_time || '')}
                                onChange={(val) => onTimeChange(val)}
                                showNow={true}
                                popupClassName={styles.popup_class}
                                format="HH:mm:ss" // Формат отображения времени
                                placeholder="Выберите время" // Плейсхолдер
                                getPopupContainer={() => document.getElementById('backup_settings')!}
                            />
                        </span>
                    </div>
                    <div className={styles.settings_s_cell}>
                        <span hidden={isHidden('week_day')}>
                        <Select style={{width: '100%'}}
                                id='week_day'
                                value={backupSettings?.start_weekday}
                                onChange={(e) => onWeekDayChange(e)}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                placeholder={'Выберите значение'}
                                options={days}/>
                         </span>

                        <span hidden={isHidden('month_day')}>
                                <Select style={{width: '100%'}}
                                        id='month_day'
                                        value={backupSettings?.start_monthday}
                                        onChange={(e) => onMonthDayChange(e)}
                                        getPopupContainer={triggerNode => triggerNode.parentNode}
                                        placeholder={'Выберите значение'}
                                        options={mDays}/>
                                </span>

                    </div>
                </div>
            </div>
        </ConfigProvider>
    )
}
