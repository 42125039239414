import styles from './MaskingRules.module.scss';
import React, {useEffect, useState} from "react";
import {Card, ConfigProvider, Table} from 'antd';

import {getMaskingRulesPageable} from '../../services/pages/masking_rule';
import {MRule} from '../../types/MaskingRule/MRule';
import {NoData} from '../../components/NoData/NoData';
import classNames from 'classnames';
import {NavButton} from '../../components/NavButton/NavButton';
import {doNavigate} from '../../utils';
import {useNavigate} from 'react-router-dom';
import {SearchInput} from '../../components/SearchInput/SearchInput';
import {map} from 'lodash';
import {SearchState} from '../../types/SearchState';
import {TableFilterItem} from '../../types/TableFilterItem';
import {FilterItem} from '../../types/FilterItem';
import {TableDropdownFilter} from '../../components/TableDropdownFilter/TableDropdownFilter';
import InfiniteScroll from 'react-infinite-scroll-component';


const defaultEditUrl = '/control/rules/edit/';

export function MaskingRulesAll() {

    const navigate = useNavigate();

    const [maskingRules, setMaskingRulesData] = useState<MRule[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');

    const [nameFilter, setNameFilter] = useState<TableFilterItem[]>( []);
    const [nameFilterItems, setNameFilterItems] = useState<FilterItem[]>( []);
    const [nameFiltered, setNameFiltered] = useState<string[]| null>( []);

    const [descFilter, setDescFilter] = useState<TableFilterItem[]>( []);
    const [descFilterItems, setDescFilterItems] = useState<FilterItem[]>( []);
    const [descFiltered, setDescFiltered] = useState<string[]| null>( []);

    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState<number>();

    const [searchState, setSearchState] = useState<SearchState>({
        filteredInfo: null,
        sortedInfo: null,
        filteredData: [],
        filtered: false,
        searchText: ""
    });

    useEffect(() => {
        loadMaskingRulesData();
    }, []);

    useEffect(()=> {
        initFilter();
    }, [maskingRules]);

    const loadMaskingRulesData = () => {
        if (loading) {
            return;
        }
        setLoading(true);

        getMaskingRulesPageable(limit, offset).then((response) => {
                let count = response['count'];
                setCount(count);
                let rules = response['items'];
                setMaskingRulesData((data) => [...data, ...rules]);
                setLoading(false);
                setOffset(offset + limit);
            }

        ).catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }

    const updateSearchState = (searchText: string) => {
        setSearchValue(searchText);
        Object.assign(searchState, {searchText: searchText});

        const filtered = map(maskingRules, record => {
            const nameMatch = record.name.includes(searchText);
            const descriptionMath = record.description?.includes(searchText);

            if (!nameMatch && !descriptionMath) {
                return null;
            }
            return record;
        }).filter(record => !!record);

        Object.assign(searchState, {filteredData: searchState.searchText ? filtered : maskingRules});
    }

    const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchState(event.target.value);
    }

    const clearSearchInput = () => {
        updateSearchState('');
    }

    const initFilter=() => {
        let names = maskingRules?.map((rule)=> rule.name);
        names = names?.filter((value, index) => names?.indexOf(value) === index);

        let desc = maskingRules?.map((rule)=> rule.description);
        desc = desc?.filter((value, index) => desc?.indexOf(value) === index);

        const nameFilter = names?.map((val) => ({text: val, value: val}));
        const nameFilterItem = names?.map((val) => ({label: val, value: val}));

        const descFilter = desc?.map((val) => ({text: val , value: val }));
        const descFilterItem = desc?.map((val) => ({label: val , value: val }));

        setNameFilterItems([...nameFilterItem]);
        setNameFilter([...nameFilter]);

        setDescFilterItems([...descFilterItem]);
        setDescFilter([...descFilter]);
    }

    const applyNameFilter = (filteredValues: string[]) => {
        setNameFiltered([...filteredValues]);
    }

    const applyDescFilter = (filteredValues: string[]) => {
        setDescFiltered([...filteredValues]);
    }


    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'rule_name',
            width: 240,
            filterDropdown: <TableDropdownFilter
                popupId={'scrollableDiv'}
                onApply={applyNameFilter}
                items={nameFilterItems}/>,
            filters: nameFilter,
            filteredValue: nameFiltered,
            onFilter: (value:any, record:any) => record.name.includes(value),
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'rule_description',
            width: 392,
            filterDropdown: <TableDropdownFilter
                popupId={'scrollableDiv'}
                onApply={applyDescFilter}
                items={descFilterItems}/>,
            filters: descFilter,
            filteredValue: descFiltered,
            onFilter: (value:any, record:any) => record.description.includes(value)
        },
        {
            title: 'Тип правила',
            dataIndex: 'rule',
            key: 'rule_rule',
            width: 260
        },
        {
            title: 'Словарь или алгоритм маскирования',
            dataIndex: 'dictionary',
            key: 'rule_dictionary',
            width: 260
        }

    ]

    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        algorithm: true,
                    },
                    Table: {
                        headerBg: '#FFFFFF',
                        colorPrimary:'#F75623',
                        algorithm: true
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder:'#D92020',
                        algorithm: true,
                    },
                    Checkbox: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        algorithm: true,
                    },
                },
            }}>
            <Card bordered={false}
                  key={'maskingRulesAll'}
                  style={{ overflowY:'auto'}}
                  className={classNames(styles.card, {
                      [styles.card_no_data]: maskingRules?.length === 0,
                  })}>

                {maskingRules?.length === 0 ? <>

                        <NoData title={'Нет правил маскирования'}
                                isHideBtn={false}
                                isNavButton={true}
                                iconName={'GroupOutlined'}
                                url={'/control/rules/create'}
                                description={'Правила маскирования не добавлены'}/>

                    </> :

                    <div className={styles.page}>


                        <div className={'header_1'}>
                            Правила маскирования

                            <SearchInput searchValue={searchValue}
                                         onChangeHandler={handleSearchInput}
                                         onClearSearchInput={clearSearchInput}/>
                        </div>

                        <div className={'header_2'}>
                            {offset < count! ? offset + ' из ' + count : count + ' из ' + count}
                        </div>
                        
                        <div className={styles.form}>

                            <div id="scrollableDiv" className={'scrollable_div'}>

                                <InfiniteScroll
                                    dataLength={maskingRules.length}
                                    next={loadMaskingRulesData}
                                    hasMore={maskingRules.length < count!}
                                    scrollThreshold={1}
                                    scrollableTarget="scrollableDiv"
                                    loader={<h6></h6>}
                                    style={{overflow:"unset"}}>

                                <Table
                                    id="masking_rules_table"
                                    rowKey={'id'}
                                    dataSource={searchState.searchText ? searchState.filteredData : maskingRules}
                                    getPopupContainer={()=>document.getElementById('masking_rules_table')!}
                                    columns={columns}
                                    pagination={false}
                                    scroll={{x:'100%'}}
                                    size="small"
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: () => {
                                                doNavigate(defaultEditUrl + `${record.id}`, navigate);
                                            }, style: {
                                                cursor: 'pointer'
                                            }
                                        };
                                    }}/>
                                </InfiniteScroll>
                            </div>

                        </div>

                    </div>}

                <div className={styles.nav_btn}>
                    <NavButton route={'/control/rules/create'}
                               btnText={'Добавить правило маскирования'}
                               className={'button_1'}/>
                </div>
            </Card>
        </ConfigProvider>
    );
}
