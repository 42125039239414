import {Card, ConfigProvider} from 'antd';
import React from 'react';
import {useParams} from 'react-router';
import {MonitoringServerChart} from './MonitoringServerChart';
import {MetricType} from '../../types/Manitoring/MetricType';

export function MonitoringServerAllCharts() {

    const {id} = useParams();
    const {name} = useParams();

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {

                        Input: {
                            colorPrimary: '#F75623',
                            activeShadow: '0 0 0 1px #F75623',
                            algorithm: true,
                        },
                        Table: {
                            headerBg: '#FFFFFF'
                        }
                    },
                }}>

                <Card bordered={false} style={{width: '100%', height: '100%', overflowY:'auto'}} key={'server'}>

                    <div>

                        <div className={'header_1'}> Сервер {name}</div>

                        <div style={{display:'flex', margin:'20px 0 0 0'}}>
                            <MonitoringServerChart
                                                   type={MetricType.ram}
                                                   lineColor={'#F75623'}
                                                   containerId={'ram_' + id}
                                                   labelY={'%'}/>

                            <MonitoringServerChart
                                                   type={MetricType.disk}
                                                   lineColor={'#1563FF'}
                                                   containerId={'used_' + id}
                                                   labelY={'%'}/>
                        </div>

                        <div style={{display:'flex', margin:'10px 0 0 0'}}>
                            <MonitoringServerChart
                                                   type={MetricType.cpu}
                                                   lineColor={'#0B682A'}
                                                   containerId={'cpu_' + id}
                                                   labelY={'%'}/>
                        </div>

                    </div>
                </Card>
            </ConfigProvider>
        </>
    )
}
