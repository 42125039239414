import styles from './Dictionaries.module.scss';
import React, {useEffect, useState} from 'react';
import {Card, ConfigProvider, Table} from 'antd';
import {getDictionariesPageable, getDictionaryById} from '../../services/pages/dictionary';
import {DictionaryItem} from '../../types/DictionaryItem';
import {NoData} from '../../components/NoData/NoData';
import classNames from 'classnames';
import {DictionaryModal} from './DictionaryModal';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {SearchInput} from '../../components/SearchInput/SearchInput';
import {assign, find, map} from 'lodash';
import {SearchState} from '../../types/SearchState';
import {TableFilterItem} from '../../types/TableFilterItem';
import {FilterItem} from '../../types/FilterItem';
import {TableDropdownFilter} from '../../components/TableDropdownFilter/TableDropdownFilter';
import InfiniteScroll from 'react-infinite-scroll-component';
import {DictionaryState} from './DictionaryState';

export function DictionariesAll() {

    const [dictionaries, setDictionariesData] = useState<DictionaryItem[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [editDictionary, setEditDictionary] = useState<DictionaryItem | null>(null);

    const [searchValue, setSearchValue] = useState<string>('');

    const [nameFilter, setNameFilter] = useState<TableFilterItem[]>([]);
    const [nameFilterItems, setNameFilterItems] = useState<FilterItem[]>([]);
    const [nameFiltered, setNameFiltered] = useState<string[] | null>([]);

    const [descFilter, setDescFilter] = useState<TableFilterItem[]>([]);
    const [descFilterItems, setDescFilterItems] = useState<FilterItem[]>([]);
    const [descFiltered, setDescFiltered] = useState<string[] | null>([]);

    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState<number>();

    const [searchState, setSearchState] = useState<SearchState>({
        filteredInfo: null,
        sortedInfo: null,
        filteredData: [],
        filtered: false,
        searchText: ""
    });


    useEffect(() => {
        loadDictionariesData();
    }, []);

    useEffect(() => {
        initFilter();
    }, [dictionaries]);

    const loadDictionariesData = () => {
        if (loading) {
            return;
        }
        setLoading(true);

        getDictionariesPageable(limit, offset).then((response) => {
                let count = response['count'];
                setCount(count);
                let dictionaries = response['items'];
                setDictionariesData((data) => [...data, ...dictionaries]);
                setLoading(false);
                setOffset(offset + limit);
            }

        ).catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }

    const onConfirm = (dictionary: DictionaryItem, isUpload: boolean) => {

        if (isUpload) {
            getDictionaryById(dictionary.id).then((resp) => {
                let dict = resp as DictionaryItem;
                setDictionariesData((data) => [...data, dict]);
                let incCount = count || 0;
                incCount += 1;
                setCount(incCount);
                setOffset(offset + 1);
            });
        } else {
            let dictionaryToUpdate = find(dictionaries, {id: dictionary.id});
            if (dictionaryToUpdate) {
                assign(dictionaryToUpdate, {
                    name: dictionary.name,
                    description: dictionary.description
                });
            }
        }
        setIsModalOpen(false);
    }

    const onCancel = () => {
        setIsModalOpen(false);
        setEditDictionary(null);
    }

    const onAddClickHandler = () => {
        setIsModalOpen(true);
    }
    const onAddClickRowHandler = (record: DictionaryItem) => {
        setIsModalOpen(true);
        setEditDictionary({...record})
    }

    const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchState(event.target.value);
    }

    const clearSearchInput = () => {
        updateSearchState('');
    }

    const updateSearchState = (searchText: string) => {
        setSearchValue(searchText);
        Object.assign(searchState, {searchText: searchText});

        const filtered = map(dictionaries, record => {
            const nameMatch = record.name.includes(searchText);
            const descriptionMath = record.description?.includes(searchText);

            if (!nameMatch && !descriptionMath) {
                return null;
            }
            return record;
        }).filter(record => !!record);

        Object.assign(searchState, {filteredData: searchState.searchText ? filtered : dictionaries});
    }

    const initFilter = () => {
        let names = dictionaries?.map((dict) => dict.name);
        names = names?.filter((value, index) => names?.indexOf(value) === index);

        let desc = dictionaries?.map((dict) => dict.description);
        desc = desc?.filter((value, index) => desc?.indexOf(value) === index);

        const nameFilter = names?.map((val) => ({text: val, value: val}));
        const nameFilterItem = names?.map((val) => ({label: val, value: val}));

        const descFilter = desc?.map((val) => ({text: val || "", value: val || ""}));
        const descFilterItem = desc?.map((val) => ({label: val || "", value: val || ""}));

        setNameFilterItems([...nameFilterItem]);
        setNameFilter([...nameFilter]);

        setDescFilterItems([...descFilterItem]);
        setDescFilter([...descFilter]);
    }

    const applyNameFilter = (filteredValues: string[]) => {
        setNameFiltered([...filteredValues]);
    }

    const applyDescFilter = (filteredValues: string[]) => {
        setDescFiltered([...filteredValues]);
    }

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'dict_name',
            filterDropdown: <TableDropdownFilter
                popupId={'scrollableDiv'}
                onApply={applyNameFilter}
                items={nameFilterItems}/>,
            filters: nameFilter,
            filteredValue: nameFiltered,
            onFilter: (value: any, record: any) => record.name.includes(value),
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'dict_description',
            filterDropdown: <TableDropdownFilter
                popupId={'scrollableDiv'}
                onApply={applyDescFilter}
                items={descFilterItems}/>,
            filters: descFilter,
            filteredValue: descFiltered,
            onFilter: (value: any, record: any) => record.description.includes(value),
        },
        {
            title: 'Словарь',
            dataIndex: 'dictionary',
            key: 'dict_dictionary',
        },
        {
            title: 'Статус обработки',
            render: (record: DictionaryItem) => {
                return <>{ <DictionaryState dictionary={record} />}
                </>
            }
        }

    ]

    return (
        <ConfigProvider
            theme={{
                components: {

                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        algorithm: true,
                    },
                    Table: {
                        headerBg: '#FFFFFF',
                        colorPrimary:'#F75623',
                        algorithm: true
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder:'#D92020',
                        algorithm: true,
                    },
                    Checkbox: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        algorithm: true,
                    },
                },
            }}>
            <Card bordered={false}
                  id="dictionariesAll"
                  key={'dictionariesAll'}
                  style={{ overflowY:'auto'}}
                  className={classNames(styles.card, {
                      [styles.card_no_data]: dictionaries?.length === 0,
                  })}>
                {dictionaries?.length === 0 ? <>

                        <NoData title={'Нет словарей'}
                                isHideBtn={false}
                                iconName={'BookOutlined'}
                                url={'/control/dictionaries/create'}
                                isNavButton={false}
                                onActionHandler={onAddClickHandler}
                                description={'Словари не добавлены'}/>

                    </> :

                    <div className={styles.page}>


                        <div className={'header_1'}>
                            Словари


                            <SearchInput searchValue={searchValue}
                                         onChangeHandler={handleSearchInput}
                                         onClearSearchInput={clearSearchInput}/>
                        </div>

                        <div className={'header_2'}>
                            {offset < count! ? offset + ' из ' + count : count + ' из ' + count}
                        </div>


                        <div className={styles.form}>

                            <div id="scrollableDiv" className={'scrollable_div'}>
                                <InfiniteScroll
                                    dataLength={dictionaries.length}
                                    next={loadDictionariesData}
                                    hasMore={dictionaries.length < count!}
                                    scrollThreshold={1}
                                    scrollableTarget="scrollableDiv"
                                    loader={<h6></h6>}
                                    style={{overflow:"unset"}}>
                                <Table
                                    id="dictionaries_table"
                                    rowKey={'id'}
                                    scroll={{x:'100%'}}
                                    dataSource={searchState.searchText ? searchState.filteredData : dictionaries}
                                    columns={columns}
                                    pagination={false}
                                    getPopupContainer={() => document.getElementById('dictionaries_table')!}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: () => {
                                                onAddClickRowHandler(record);
                                            }, style: {
                                                cursor: 'pointer'
                                            }
                                        };
                                    }}
                                    sticky={true}
                                    size="small"/>
                                </InfiniteScroll>
                            </div>

                        </div>


                    </div>}
                <div className={styles.nav_btn}>
                    <ActionButton btnText={'Добавить словарь'}
                                  onClickHandler={onAddClickHandler}
                                  type={'default'}
                                  htmlType={'button'} className={'button_1'}/>
                </div>

                <DictionaryModal title={'Добавление словаря'}
                                 isModalOpen={isModalOpen}
                                 editDictionary={editDictionary}
                                 onConfirmHandler={onConfirm}
                                 onCancelHandler={onCancel}/>
            </Card>
        </ConfigProvider>
    );
}
